import * as Types from '../../graphql/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WorkOrderOrganizedFilterQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type WorkOrderOrganizedFilterQuery = (
  { __typename?: 'Query' }
  & { stoppageReasons: Array<(
    { __typename?: 'StoppageReason' }
    & Pick<Types.StoppageReason, 'id' | 'name'>
  )>, users: Array<(
    { __typename?: 'User' }
    & Pick<Types.User, 'id' | 'name'>
  )> }
);


export const WorkOrderOrganizedFilterDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"WorkOrderOrganizedFilter"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"stoppageReasons"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}},{"kind":"Field","name":{"kind":"Name","value":"users"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useWorkOrderOrganizedFilterQuery__
 *
 * To run a query within a React component, call `useWorkOrderOrganizedFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkOrderOrganizedFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkOrderOrganizedFilterQuery({
 *   variables: {
 *   },
 * });
 */
export function useWorkOrderOrganizedFilterQuery(baseOptions?: Apollo.QueryHookOptions<WorkOrderOrganizedFilterQuery, WorkOrderOrganizedFilterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkOrderOrganizedFilterQuery, WorkOrderOrganizedFilterQueryVariables>(WorkOrderOrganizedFilterDocument, options);
      }
export function useWorkOrderOrganizedFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkOrderOrganizedFilterQuery, WorkOrderOrganizedFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkOrderOrganizedFilterQuery, WorkOrderOrganizedFilterQueryVariables>(WorkOrderOrganizedFilterDocument, options);
        }
export function useWorkOrderOrganizedFilterSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<WorkOrderOrganizedFilterQuery, WorkOrderOrganizedFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WorkOrderOrganizedFilterQuery, WorkOrderOrganizedFilterQueryVariables>(WorkOrderOrganizedFilterDocument, options);
        }
export type WorkOrderOrganizedFilterQueryHookResult = ReturnType<typeof useWorkOrderOrganizedFilterQuery>;
export type WorkOrderOrganizedFilterLazyQueryHookResult = ReturnType<typeof useWorkOrderOrganizedFilterLazyQuery>;
export type WorkOrderOrganizedFilterSuspenseQueryHookResult = ReturnType<typeof useWorkOrderOrganizedFilterSuspenseQuery>;
export type WorkOrderOrganizedFilterQueryResult = Apollo.QueryResult<WorkOrderOrganizedFilterQuery, WorkOrderOrganizedFilterQueryVariables>;