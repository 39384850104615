import CommonOrganizedFilterWrapper, {
  CustomFieldOptionType,
  OrganizedFilterItemsType,
} from '@/common/components/CommonOrganizedFilterWrapper';
import CustomFieldOptionsFilter from '@/common/components/CustomFieldOptionsFilter';
import CustomMenuItem from '@/common/components/CustomMenuItem';
import DropdownMenu from '@/common/components/DropdownMenu';
import MultipleLayerCheckbox from '@/common/components/MultipleLayerCheckbox';
import { OptionValue } from '@/common/types';
import { useWorkOrderStatus } from '@/modules/workOrders/hooks/useWorkOrderStatus';
import { WorkOrderNotDoneStatuses } from '@/modules/workOrders/types/workOrder';
import useTranslation from '@/utils/i18n/useTranslation';
import { gql } from '@apollo/client';
import { MenuItemOption, MenuOptionGroup } from '@chakra-ui/react';
import { useWorkOrderOrganizedFilterQuery } from './WorkOrderOrganizedFilter.generated';
import { workOrderPriorityLabelOptions } from './WorkOrderPriorityLabel';

export const defaultOrganizedFilterItemsOpenValue: OrganizedFilterItemsType = {
  creator: false,
  priority: false,
  status: false,
  others: false,
  stoppageReasonIds: false,
};

type WorkOrderOrganizedFilterProps = {
  statusLabel: string;
  organizedFiltersCount: number;
  createdByIds: string[];
  priorities: string[];
  statuses: string[];
  isDone?: boolean;
  otherFilters: string[];
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  resetOrganizedFilter: () => void;
  filterOtherChange: (values: string[]) => void;
  filterCreatedByIds: (ids?: OptionValue[]) => void;
  filterPrioritiesChange: (values: string[]) => void;
  setOrganizedFilterItemsOpenValue: (value: OrganizedFilterItemsType) => void;
  resetOrganizedFilterItemsOpenValue: () => void;
  filterStatusChange: (values: string[]) => void;
  filteredOtherFilterItems: { value: string }[];
  organizedFilterItemsOpenValue: OrganizedFilterItemsType;
  customFieldOptions: CustomFieldOptionType[];
  customFieldOptionsMap: Map<number, number[]>;
  filterCustomFieldOptionIds: (customFieldId: number, optionIds?: OptionValue[]) => void;
  stoppageReasonIds: number[];
  setStoppageReasonIds: (value: number[]) => void;
};

const filterValuesMap = {
  hasPart: 'has-part',
  hasAttachment: 'has-attachment',
  hasComment: 'has-comment',
};

export type filterValuesKeysType = 'hasPart' | 'hasAttachment' | 'hasComment';

const dropdownContainerHeight = 430;

gql`
  query WorkOrderOrganizedFilter {
    stoppageReasons {
      id
      name
    }
    users {
      id
      name
    }
  }
`;

const WorkOrderOrganizedFilter = (props: WorkOrderOrganizedFilterProps) => {
  const {
    statusLabel,
    filterOtherChange,
    filterCreatedByIds,
    resetOrganizedFilter,
    organizedFiltersCount,
    filterPrioritiesChange,
    filteredOtherFilterItems,
    organizedFilterItemsOpenValue,
    setOrganizedFilterItemsOpenValue,
    resetOrganizedFilterItemsOpenValue,
    priorities,
    createdByIds,
    statuses,
    isDone,
    otherFilters,
    filterStatusChange,
    isOpen,
    setIsOpen,
    customFieldOptions,
    customFieldOptionsMap,
    filterCustomFieldOptionIds,
    stoppageReasonIds,
    setStoppageReasonIds,
  } = props;

  const { t } = useTranslation();
  const { getStatusLabel } = useWorkOrderStatus();

  const { data } = useWorkOrderOrganizedFilterQuery();

  return (
    <CommonOrganizedFilterWrapper
      organizedFiltersCount={organizedFiltersCount}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      resetOrganizedFilter={resetOrganizedFilter}
      resetOrganizedFilterItemsOpenValue={resetOrganizedFilterItemsOpenValue}
    >
      <CustomMenuItem>
        <DropdownMenu
          label={t('creator')}
          values={createdByIds}
          containerSx={{ minH: dropdownContainerHeight }}
          isOpen={organizedFilterItemsOpenValue.creator}
          onToggle={() =>
            setOrganizedFilterItemsOpenValue({
              ...defaultOrganizedFilterItemsOpenValue,
              creator: !organizedFilterItemsOpenValue.creator,
            })
          }
          onClose={resetOrganizedFilterItemsOpenValue}
        >
          <MultipleLayerCheckbox
            label={t('creator')}
            isDefaultOpen
            options={data?.users?.map((user) => ({ id: user.id, label: user.name })) || []}
            onChange={filterCreatedByIds}
            values={createdByIds}
            onCloseFilter={resetOrganizedFilterItemsOpenValue}
            isInMenu={true}
          />
        </DropdownMenu>
      </CustomMenuItem>
      <CustomMenuItem>
        <DropdownMenu
          label={t('priority.title')}
          values={priorities}
          isOpen={organizedFilterItemsOpenValue.priority}
          onToggle={() =>
            setOrganizedFilterItemsOpenValue({
              ...defaultOrganizedFilterItemsOpenValue,
              priority: !organizedFilterItemsOpenValue.priority,
            })
          }
          onClose={resetOrganizedFilterItemsOpenValue}
          onClickClearSelection={() => filterPrioritiesChange([])}
        >
          <MenuOptionGroup
            value={priorities}
            type='checkbox'
            onChange={(value) => filterPrioritiesChange(value as string[])}
          >
            {workOrderPriorityLabelOptions.map((option, index) => (
              <MenuItemOption value={option.value} key={index}>
                {t(`priority.${option.value}`)}
              </MenuItemOption>
            ))}
          </MenuOptionGroup>
        </DropdownMenu>
      </CustomMenuItem>
      <CustomMenuItem>
        <DropdownMenu
          label={t('stoppage-reason.title')}
          values={stoppageReasonIds}
          containerSx={{ minH: dropdownContainerHeight }}
          isOpen={organizedFilterItemsOpenValue.stoppageReasonIds}
          onToggle={() =>
            setOrganizedFilterItemsOpenValue({
              ...defaultOrganizedFilterItemsOpenValue,
              stoppageReasonIds: !organizedFilterItemsOpenValue.stoppageReasonIds,
            })
          }
          onClose={resetOrganizedFilterItemsOpenValue}
          onClickClearSelection={() => setStoppageReasonIds([])}
        >
          <MultipleLayerCheckbox
            label={t('stoppage-reason.title')}
            isDefaultOpen
            options={
              data?.stoppageReasons?.map((reason) => ({ id: reason.id, label: reason.name })) || []
            }
            onChange={(values) => setStoppageReasonIds(values as number[])}
            values={stoppageReasonIds}
            onCloseFilter={() => setStoppageReasonIds([])}
            isInMenu={true}
          />
        </DropdownMenu>
      </CustomMenuItem>
      <CustomMenuItem>
        {!isDone && (
          <DropdownMenu
            label={statusLabel}
            values={statuses}
            isOpen={organizedFilterItemsOpenValue.status}
            onToggle={() =>
              setOrganizedFilterItemsOpenValue({
                ...defaultOrganizedFilterItemsOpenValue,
                status: !organizedFilterItemsOpenValue.status,
              })
            }
            onClose={resetOrganizedFilterItemsOpenValue}
            onClickClearSelection={() => filterStatusChange([])}
          >
            <MenuOptionGroup
              value={statuses}
              type='checkbox'
              onChange={(value) => filterStatusChange(value as string[])}
            >
              {WorkOrderNotDoneStatuses.map((value, index) => (
                <MenuItemOption value={value} key={index}>
                  {getStatusLabel(value)}
                </MenuItemOption>
              ))}
            </MenuOptionGroup>
          </DropdownMenu>
        )}
      </CustomMenuItem>
      <CustomFieldOptionsFilter
        organizedFilterItemsOpenValue={organizedFilterItemsOpenValue}
        customFieldOptions={customFieldOptions}
        customFieldOptionsMap={customFieldOptionsMap}
        defaultOrganizedFilterItemsOpenValue={defaultOrganizedFilterItemsOpenValue}
        setOrganizedFilterItemsOpenValue={setOrganizedFilterItemsOpenValue}
        resetOrganizedFilterItemsOpenValue={resetOrganizedFilterItemsOpenValue}
        filterCustomFieldOptionIds={filterCustomFieldOptionIds}
      />
      <CustomMenuItem>
        <DropdownMenu
          label={t('additional-info')}
          values={otherFilters}
          isOpen={organizedFilterItemsOpenValue.others}
          onToggle={() =>
            setOrganizedFilterItemsOpenValue({
              ...defaultOrganizedFilterItemsOpenValue,
              others: !organizedFilterItemsOpenValue.others,
            })
          }
          onClose={resetOrganizedFilterItemsOpenValue}
          onClickClearSelection={() => filterOtherChange([])}
        >
          <MenuOptionGroup
            value={otherFilters}
            type='checkbox'
            onChange={(value) => filterOtherChange(value as string[])}
          >
            {filteredOtherFilterItems.map((option, index) => (
              <MenuItemOption value={option.value} key={index}>
                {t(`filter.${filterValuesMap[option.value as filterValuesKeysType]}`)}
              </MenuItemOption>
            ))}
          </MenuOptionGroup>
        </DropdownMenu>
      </CustomMenuItem>
    </CommonOrganizedFilterWrapper>
  );
};

export default WorkOrderOrganizedFilter;
